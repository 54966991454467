import * as React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import "./footer.css"

const Footer = () => (
        <footer
          className="footer d-print-none bg-light"
        >

        <div className="container-fluid">
          <div className="container">
              <p className="h4 mb-3 publico-text pt-5">About BNY Mellon and Our <span className="publico-text-web">Future First<sup>SM</sup></span> Platform</p>
              <div className="row">  
                <div className="col-md-9">  
                  
                  <p className="small">At BNY Mellon, we’re committed to putting the <span className="superitalic">Future First<sup>SM</sup></span> by using our global reach, influence, and resources not just to power success today, but to help safeguard the future.</p>
                  <p className="small">Delivering on our environmental, social and governance (ESG) strategy, we <span className="superitalic">Consider Everything<sup>SM</sup></span>, starting with our own enterprise-wide practices addressing the business impacts of global issues and contributing to opportunities that help communities thrive. We expand our actions by providing leading products and services that empower our clients to meet their own goals.</p>
                  <p className="small">This way, we accelerate the evolution of ESG – on behalf of clients, investors, communities and all stakeholders – to make a positive impact on people and the planet.</p>
                  <p className="small mb-0">Disclosure:</p>  
                  <p className="small">
                    <a
                    rel="noreferrer"
                    className={`a-footer-underline`}
                    title="BNY Mellon - Disclosure"
                    target="_blank" 
                    onClick={()=>{
                      if(window.analytics){
                        window.analytics('send', 
                          {
                            hitType: 'event',
                            eventCategory: 'footer',
                            eventAction: 'click',
                            eventLabel: 'disclosure'
                          }
                        );
                      }
                    }}
                    href={`https://www.bnymellon.com/us/en/disclaimers/business-disclaimers.html#as`}
                    >
                      https://www.bnymellon.com/us/en/disclaimers/business-disclaimers.html#as
                    </a>
                  </p>

                  <ul className="">  
                     <li className="list-inline-item policy-link mb-4">
                        <a
                          rel="noreferrer"
                          className={`a-footer-underline`} 
                          href="https://www.bnymellon.com/us/en/data-privacy.html" 
                          title="BNY Mellon - Privacy Policy" 
                          data-linkloc="footer"
                          target="_blank" 
                          onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'policy'
                                }
                              );
                            }
                          }}
                       >GLOBAL PRIVACY NOTICES</a>
                     </li>
                  </ul> 


                </div>  
                <div className="col-md-3">  
                  <p className="h5 mb-3 d-none"></p>  
                  <p className="h5 mb-2">Connect with Us</p>
                  <ul className="list-unstyled mb-4 sociallink">
                    <li><a href={"https://www.facebook.com/bnymellon"} 
                    rel="noreferrer"
                    data-linkloc="footer"
                    target="_blank" 
                    onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'Facebook'
                                }
                              );
                            }
                    }}
                    >{`Facebook `} 
                   <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/>
                    </a></li>
                    <li><a 
                    href={"https://www.twitter.com/bnymellon"} 
                    rel="noreferrer"
                    data-linkloc="footer"
                    target="_blank" 
                    onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'Twitter'
                                }
                              );
                            }
                    }}
                    >{`Twitter `}
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/>
                    </a></li>
                    <li><a 
                    rel="noreferrer"
                    href={"https://www.linkedin.com/company/bny-mellon"} data-linkloc="footer"
                    target="_blank"
                    onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'LinkedIn'
                                }
                              );
                            }
                    }}
                    >{`LinkedIn `}
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/>
                    </a></li>
                  </ul> 
                  <p className="h5 mb-2">More from BNY Mellon</p> 
                  <p className="mb-0"> 
                    <a href="https://www.bnymellon.com/?cid=ALL_REF_XC003FFForum_DisclosureNav_ALLSEG_PUB_GL_OTH_____" data-linkloc="footer" title="BNY Mellon Events"
                    rel="noreferrer"
                    target="_blank" 
                    onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'bnymellon'
                                }
                              );
                            }
                    }}
                    >bnymellon.com</a>
                  </p>

                  <p className="mb-4"> 
                    <a href="https://www.bnymellon.com/us/en/about-us/esg-and-responsible-investment.html " data-linkloc="footer" title="ESG & Responsible Investment"
                    rel="noreferrer"
                    target="_blank" 
                    onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'ESG & Responsible Investment'
                                }
                              );
                            }
                    }}
                    >ESG & Responsible Investment</a>
                  </p>

                  <p className="mb-0"> 
                    <a 
                    href="mailto:futurefirstforum@bnymellon.com?subject=Future%20First℠%20Forum%20|%20May%2012,%202021" data-linkloc="footer" title="CONTACT US"
                    onClick={()=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'footer',
                                  eventAction: 'click',
                                  eventLabel: 'contactus'
                                }
                              );
                            }
                    }}
                    >CONTACT US</a>
                  </p>

                </div>
              </div>
              <div className="row mt-4 pb-4"> 
                <div className="col-12 small">  
                  <p className="border-top pt-4 list-inline">© 2021 THE BANK OF NEW YORK MELLON CORPORATION</p> 
                </div>  
              </div>
          </div>
        </div>






        </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
