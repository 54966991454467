import * as React from "react"

import Logo from '../assets/logo.svg';
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./header.css"

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = ({ siteTitle }) => (
      <header className="d-print-none fixed-top">

        <nav className="navbar main-nav navbar-expand-lg media-box pt-0 pt-lg-3 pb-0 pb-lg-3"> 
          <div className="container pl-3 pr-0 pr-sm-3 align-items-center">
            
            <Link 
              to="/"
              className="navbar-brand" 
              onClick={()=>{
                  if(window.analytics){
                    window.analytics('send', 
                      {
                        hitType: 'event',
                        eventCategory: 'header',
                        eventAction: 'click',
                        eventLabel: 'logo'
                      }
                    );
                  }
              }}
            >
              <Logo alt="Logo"/>
        
            </Link>

            <button className="navbar-toggler btn" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-controls="collapsibleNavbar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <FontAwesomeIcon icon={faBars} className="d-none d-lg-block" size="2x"/>
                <FontAwesomeIcon icon={faBars} className="d-block d-lg-none" size="1x"/>
              </span>  
            </button> 

            <div className="collapse navbar-collapse mt-0 mt-sm-3 mt-lg-0 mb-3 mb-lg-0" id="collapsibleNavbar">
              <ul className="navbar-nav ml-auto text-uppercase">
                <li className={"nav-item ml-3"} >
                  <Link
                    to="/agenda" 
                    activeClassName="active"
                    className="nav-link px-3 px-lg-3 px-xl-1 text-right text-lg-left" 
                    data-linkloc="header" 
                    onClick={()=>{
                      if(window.analytics){
                        window.analytics('send', 
                          {
                            hitType: 'event',
                            eventCategory: 'header',
                            eventAction: 'click',
                            eventLabel: 'agenda'
                          }
                        );
                      }
                    }}
                  >AGENDA</Link>
                </li>
                <li className={"nav-item ml-3"} >
                  <Link
                    to="/speakers"
                    activeClassName="active"
                    className="nav-link px-3 px-lg-3 px-xl-1 text-right text-lg-left" 
                    data-linkloc="header" 
                    onClick={()=>{
                      if(window.analytics){
                        window.analytics('send', 
                          {
                            hitType: 'event',
                            eventCategory: 'header',
                            eventAction: 'click',
                            eventLabel: 'speakers'
                          }
                        );
                      }
                    }}
                  >SPEAKERS</Link>
                </li>
              
                <li className={"nav-item ml-3"} >
                  <Link 
                    to="/insights"
                    activeClassName="active"
                    className="nav-link px-3 px-lg-3 px-xl-1 text-right text-lg-left" 
                    data-linkloc="header" 
                    onClick={()=>{
                      if(window.analytics){
                        window.analytics('send', 
                          {
                            hitType: 'event',
                            eventCategory: 'header',
                            eventAction: 'click',
                            eventLabel: 'insights'
                          }
                        );
                      }
                    }}
                  >ESG Insights</Link>
                </li>
              

              </ul>
            </div>
          
          </div>
        </nav>
      </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
